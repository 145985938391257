.charts-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
    gap: 2rem;
    padding: 2rem;
    background-color: #f9fafb;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .dataCard {
    padding: 1.5rem;
    background: linear-gradient(145deg, #ffffff, #f3f4f6);
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), inset 0 2px 4px rgba(255, 255, 255, 0.6);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    height: 500px; /* Set a fixed height for the card */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .dataCard canvas {
    height: 400px !important; /* Ensure the canvas takes up the majority of the card */
  }
  
  
  .dataCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  }
  
  .summaryCard {
    border-left: 5px solid #36a2eb;
    background: linear-gradient(135deg, #e0f7fa, #ffffff); /* Light blue gradient */
  }
  
  .participantsCard {
    border-left: 5px solid #ff6384;
    background: linear-gradient(135deg, #fdecef, #ffffff); /* Light pink gradient */
  }
  
  .dataCard h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .dataCard canvas {
    max-height: 400px;
  }
  