@import url("https://fonts.googleapis.com/css2?family=Clicker+Script&family=Pacifico&family=Poppins:wght@200;300;400;500;600&family=Satisfy&family=Titillium+Web&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

:root {
  --bg-black-900: #f2f2fc;
  --bg-black-100: #fdf9ff;
  --bg-black-50: #e8dfec;
  --bg-black-25: #d9d0dd;
  --text-black-900: #302e4d;
  --text-black-700: #58567c;
  --skin-color: #02a06b;
  --secondary-color: #5dce9c;
  --skin-gradient: linear-gradient(97.05deg, #01bd7e 3.76%, #02a06b 100%);
  --shadow: 0px 23px 21px -8px rgba(136, 160, 255, 0.25);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  font-family: "Poppins";
}

/* Edit Scrollbar */
::-webkit-scrollbar {
  height: 0;
  width: 0.25rem;
}

::-webkit-scrollbar-track {
  background-color: var(--bg-black-100);
}

::-webkit-scrollbar-thumb {
  background: var(--skin-color);
  border-radius: 5rem;
}

/* Registration Form Stepper Color */
.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: var(--skin-color) !important;
}

.alert-message{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 1rem 3rem 1rem;
}

.form-alert-message {
  width: 50%;
  background: red;
  color: white;
  padding: 10px 20px;
  margin: 1rem;
  border-radius: 10px;
  text-align: center;
  font-weight: bold;
  display: none;
}

/* Common Classes */
.margin-top-max {
  margin-top: 4.5rem !important;
}

.margin-top-ultra-max {
  margin-top: 6rem !important;
}

.main-heading-center {
  font-size: 32px;
  margin: 2rem 0;
  text-transform: uppercase;
  text-align: center;
}

.my-btn {
  font-size: 16px;
  font-weight: 500;
  padding: 12px 30px;
  color: white;
  border-radius: 1.5rem;
  display: inline-block;
  white-space: nowrap;
  border: none;
  background: var(--skin-gradient);
  transition: all 0.3s ease;
}

.my-btn:hover {
  transform: scale(1.05);
}

.btn-style {
  width: 6rem;
  height: 3rem;
  border-radius: 1.5rem;
  color: #fff;
  background-color: var(--skin-color);
  text-transform: capitalize;
  font-weight: 600;
  margin-left: 0.3rem;
  border: none;
  transition: 0.1s ease-in;
}

.btn-style:hover {
  border: 0.1rem solid var(--skin-color);
  background-color: transparent;
  color: var(--skin-color);
  box-shadow: 0 0.2rem 0.5rem rgba(236, 24, 57, 0.1);
}

.sub-heading {
  font-size: 1.5rem;
  font-weight: normal;
}

.paragraph {
  font-size: 1rem;
  color: var(--text-black-700);
  margin: 1rem !important;
  text-align: justify;
}

.font-awesome-style {
  font-size: 2.3rem;
  color: var(--skin-color);
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: var(--bg-black-100);
  display: grid;
  place-items: center;
  margin: 2rem auto;
  border: 1px solid var(--bg-black-50);
}

.button {
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  color: white;
  background: var(--skin-gradient);
  border: none;
  border-radius: 4px;
  transition: all 300ms ease-in;
  cursor: pointer;
}

.state-toggler {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.state-button-active {
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  color: white;
  background: var(--skin-color);
  border: 2px solid var(--skin-color);
  border-radius: 8px;
  transition: all 300ms ease-in;
  cursor: pointer;
  margin: 1rem 2rem;
}

.state-button-inactive {
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  color: var(--skin-color);
  background: transparent;
  border: 2px solid var(--skin-color);
  border-radius: 8px;
  transition: all 300ms ease-in;
  cursor: pointer;
  margin: 1rem 2rem;
}

.button:hover,
.state-button-active:hover,
.state-button-inactive:hover {
  transform: scale(1.025);
}

/* Extra Classes */

.paddings {
  padding: 1.5rem;
}

.innerWidth {
  width: 100%;
}

.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexColCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flexColStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.flexColEnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.primaryText {
  font-weight: bold;
  font-size: 2rem;
}

.secondaryText {
  color: var(--skin-color);
  font-size: 1.5rem;
  font-weight: 600;
}

/* marquee */


.marquee-container {
  background: var(--skin-color);
  color: #fff;
  display:flex  ;

  justify-content: center;
  align-items: center;
  padding: .2rem 0;
 

}
.marquee-text{
  display: flex;
  align-items: center;
  margin-right: 2rem;
  

}




/* media queries */
@media (min-width: 1536px) {
  .innerWidth {
    max-width: 1280px;
    margin: auto;
  }
}

@media (min-width: 640px) {
  .paddings {
    padding: 4rem;
  }
}

@media (min-width: 1280px), (min-width: 768px) {
  .paddings {
    padding: 2rem;
  }
}

@media (max-width: 640px) {
  .primaryText {
    font-size: 1.5rem;
  }
  .form-alert-message{
    width: 100%;
  }
}

@media (max-width: 768px) {
  .state-toggler {
    flex-direction: column;
  }
}
