.form-wrap {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
  margin-top: 1.5rem;
}
.form-container {
  margin-top: 2rem;
  max-width: 800px;
  width: 100%;
  padding: 20px;
  border: 1px solid var(--bg-black-25);
  border-bottom: none;
  border-radius: 8px;
  background-color: var(--bg-black-900);
  box-shadow: 0 2.5px 2px -1px rgba(32, 31, 31, 0.45);
}

.form-title {
  padding: 10px;
  color: var(--text-black-900);
  text-align: center;
  font-size: 1.35rem;
  text-transform: capitalize;
  letter-spacing: 0;
}

.progress-bar {
  margin: 25px 0;
  padding: 0;
  overflow: hidden;
  counter-reset: step;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.progress-bar li {
  list-style-type: none;
  width: 100%;
  color: var(--text-black-900);
  text-align: center;
  position: relative;
}

.progress-bar li .step-title {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  height: 80px;
}

.progress-bar li::before {
  position: relative;
  z-index: 2;
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
  font-size: 1rem;
  text-align: center;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  background-color: #a7a5bc;
  margin: 0 auto 10px auto;
  color: #f5f5f5;
}

.progress-bar .line-bar::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #babac0;
  position: absolute;
  left: -50%;
  top: 15px;
  z-index: 1;
  animation: effect 1s linear infinite;
  -webkit-animation: effect 1.5s linear infinite;
}

.progress-bar li.active::after,
.progress-bar li.active::before {
  background: linear-gradient(
    to right,
    #02a06b 20%,
    #03bd7f 40%,
    #04be80 60%,
    #02a06b 80%
  );
  background-size: 200% auto;
  animation: effect 1s linear infinite;
  -webkit-animation: effect 1.5s linear infinite;
  color: #fff;
}

@keyframes effect {
  to {
    background-position: -200% center;
  }
}

.step-group {
  margin-top: 50px;
}

.form-box {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.form-group {
  width: 100%;
  margin-bottom: 15px;
}

.form-group .form-field {
  width: 100%;
  border-radius: 25px;
  background: var(--bg-black-100);
  border: 1.5px solid var(--bg-black-25);
  padding: 10px 25px;
  font-size: 16px;
  color: var(--text-black-700);
  transition: all 0.5s ease;
}

.form-group .form-field:hover {
  border: 1.5px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 30px rgba(16, 15, 27, 0.15);
}

.form-group .form-field:focus {
  box-shadow: 0 0 30px rgba(16, 15, 27, 0.15);
  border: 1.5px solid var(--skin-color);
}

::placeholder {
  color: #cac1ce;
}

.form-group .form-field,
.form-dropdown .form-dropdown-btn {
  -webkit-animation: fadeIn 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fadeIn 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.btn-step {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
  justify-content: flex-end;
}

.btn-step button {
  border: 2px solid #4dcd94;
  background: transparent;
  border-radius: 1.5rem;
  color: #4dcd94;
  padding: 10px 25px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s linear;
}

.btn-step button:hover {
  background: #4dcd94;
  color: white;
  transform: scale(1.03);
}

#step-group-2,
#step-group-3 {
  display: none;
}

#success-box {
  background-color: var(--bg-black-900);
  height: 350px;
  display: none;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
}

#success-box span {
  display: block;
  width: 100px;
  height: 100px;
  border: 6px solid green;
  font-size: 60px;
  font-weight: 80;
  color: green;
  border-radius: 50%;
}

#success-box #reset-btn {
  background-color: var(--secondary-color);
  border: none;
  color: #fff;
  padding: 10px 25px;
  font-size: 20px;
  cursor: pointer;
}

/* form-dropdown Style */
.form-dropdown {
  width: 100%;
  user-select: none;
  position: relative;
}

.form-dropdown .form-dropdown-btn {
  background: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  outline: none;
  border-radius: 25px;
  background: var(--bg-black-100);
  padding: 10px 25px;
  font-size: 16px;
  transition: all 0.5s ease;
}

.active-border:hover {
  border: 1.5px solid var(--skin-color) !important;
  box-shadow: 0 0 30px rgba(16, 15, 27, 0.15) !important;
}

.normal-border:hover {
  border: 1.5px solid rgba(0, 0, 0, 0.3) !important;
  box-shadow: none !important;
}

.form-dropdown .form-dropdown-btn:hover {
  border: 1.5px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 30px rgba(16, 15, 27, 0.15);
}

.form-dropdown .form-dropdown-content {
  position: absolute;
  top: 110%;
  left: 0;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.1);
  color: var(--text-black-700);
  width: 100%;
  border-radius: 25px;
  background: #ffffff;
  border: 1.5px solid var(--bg-black-50);
  padding: 10px 15px;
  font-size: 16px;
  overflow: hidden !important;
  z-index: 2 !important;
}

.form-dropdown .form-dropdown-content .form-dropdown-item {
  padding: 8px 10px;
  margin: 4px 0;
  cursor: pointer;
  transition: all 0.3s;
  border-bottom: 0.5px solid #dad9d9;
}

.form-dropdown .form-dropdown-content .form-dropdown-item:last-child {
  border-bottom: none;
}

.form-dropdown .form-dropdown-content .form-dropdown-item:hover {
  background: #f5f5f5;
}

.label {
  color: var(--skin-color);
  margin: 20px 0;
  font-size: 16px;
}

/* Custom File Input Field */
.drag-area {
  height: 200px;
  display: flex;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  border: 2px dashed var(--skin-color);
}

.drag-area .custom-file-input {
  background: var(--skin-gradient);
  color: white;
  padding: 0.75rem;
  font-family: sans-serif;
  border-radius: 8px;
  cursor: pointer;
  margin: 1.5rem auto;
  box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in;
}
.drag-area .custom-file-input:hover {
  transform: scale(1.025);
}

.file-name {
  margin-top: 10px;
  color: var(--text-black-700);
}

/* Review Details */
.review-box {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.review-title {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  text-transform: uppercase;
  color: var(--skin-color);
  font-size: 1.35rem;
  font-weight: bold;
  margin: 5rem auto 2rem auto;
}

.data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 2px solid var(--bg-black-25);
}

.data-heading {
  font-weight: bold;
  font-size: 1rem;
  text-transform: capitalize;
  margin-right: 1rem;
}

/* Payment page Design */
.qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0.5rem 4rem 0.5rem;
}

.qr-code img {
  width: 50%;
}

/* Media Query */
@media (max-width: 992px) {
  .review-box {
    flex-direction: column;
    gap: 0;
  }
}

@media (max-width: 768px) {
  .form-container{
    margin-top: 3rem;
  }
}

@media screen and (max-width: 576px) {
  .form-container{
    margin-top: 4rem;
  }

  .form-box {
    flex-direction: column;
    gap: 0;
  }

  .progress-bar li .step-title {
    font-size: 0.75rem;
  }

  .btn-step {
    justify-content: space-between;
  }

  .btn-step button {
    padding: 6px 16px;
  }

  .qr-code img {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .form-title{
    font-size: 1.2rem;
  }

  .progress-bar li .step-title {
    font-size: 0.6rem;
    font-weight: bold;
  }
}
