/* Top Banner */
.banner {
  width: 100%;
}

.banner img{
  width: 100vw;
}

/* Winners Slider Section */
/* ================================= */
.container-slider {
  max-width: 50vw;
  height: 30vw;
  margin: 100px auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  background: var(--text-black-700);
  border-radius: 1rem;
  border: 2px solid var(--bg-black-50);
}

.container-slider .slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  border-radius: 1rem;
  transition: opacity ease-in 0.5s;
}

.container-slider .slide img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  object-fit: cover;
}

.container-slider .active-anim {
  opacity: 1;
}

.container-slider .btn-slide {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
  border: 1.5px solid var(--text-black-900);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.container-slider .btn-slide i {
  font-size: 1.5rem;
  pointer-events: none;
}

.container-slider .prev {
  top: 50%;
  left: 20px;
  transform: translateY(-60%);
}

.container-slider .next {
  top: 50%;
  right: 20px;
  transform: translateY(-60%);
}

.container-slider .container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.container-slider .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--bg-black-900);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.container-slider .active-dot {
  background: var(--skin-gradient);
  border: 1.5px solid var(--bg-black-50);
}

/* Glimpse Gallery */
.winners .committee-top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.winners .committee-top img {
  width: 100px;
  margin: 1rem;
}

.winners .committee-top h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-black-900);
}

.winners .committee-top span {
  font-size: 1rem;
  color: var(--text-black-700);
}

.winners .committee-top .bottom-bar {
  width: 50px;
  height: 2px;
  border: 2px solid var(--secondary-color);
  margin: 1rem 0;
}

.glimpse-card {
  gap: 0.6rem;
  padding: 0.5rem;
  border: none;
  border-radius: 10px;
  max-width: max-content;
  height: 250px;
  background-color: transparent;
  margin: 1rem auto;
  transition: all 500ms ease-in;
}

.glimpse-card:hover {
  scale: 1.025;
  box-shadow: 0px 0px 0px 0px rgba(122, 251, 142, 0.21);
}
.glimpse .glimpse-card > img {
  width: 100%;
  height: 200px;
  border: 2px solid var(--bg-black-50);
  border-radius: 20px;
  transition: all 500ms ease-in;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
}

.summary{
  margin: 3rem auto 3rem auto;
}

.summary h3{
  color: var(--skin-color);
  margin: 2rem auto;
}

.summary span{
  font-size: 1rem;
  color: var(--text-black-700);
  display: inline-flex;
  text-align: justify;
}


/* Why to join SIH */
.why-join-sih {
  margin: 2rem auto 5rem auto;
  padding: 2.5rem 0;
}

.why-join-sih .main-heading {
  font-size: 32px;
  margin: 2rem 0;
  text-transform: uppercase;
  font-weight: bold;
}

.sih-benefits {
  border-bottom: 3px solid var(--secondary-color);
}

.why-join-sih .sih-benefits .font-awesome-style {
  text-align: left;
  margin: 2rem 0;
}

.why-join-sih .sih-benefits .sub-heading {
  margin: 1rem;
  color: var(--skin-color);
  text-transform: uppercase;
  text-align: start;
  font-weight: bold;
  font-size: 1.25rem;
}


/* Media Query */

@media (min-width: 1400px) {
  .sih-container .col-xxl-4 {
    flex: 0 0 auto;
    width: 30% !important;
    margin: auto;
  }
}

@media (min-width: 992px) {
  .sih-container .col-lg-4 {
    flex: 0 0 auto;
    width: 30% !important;
    margin: 2.5rem auto;
  }

  .sih-benefits {
    margin: 3rem 0;
  }
}


@media screen and (max-width: 1300px) {
  .container-slider .btn-slide {
    width: 40px !important;
    height: 40px !important;
  }

  .container-slider .btn-slide i {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 992px) {
  .container-slider {
    max-width: 80vw;
    height: 50vw;
  }
}

@media screen and (max-width: 768px) {
  .glimpse .theme-head .primaryText {
    font-size: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .container-slider .btn-slide {
    width: 30px !important;
    height: 30px !important;
  }

  .container-slider .btn-slide i {
    font-size: 0.8rem !important;
  }

  .container-slider .prev {
    left: 10px;
  }

  .container-slider .next {
    right: 10px;
  }

  .container-slider .container-dots {
    gap: 8px;
  }
  .container-slider .dot {
    width: 8px;
    height: 8px;
  }

  .prizes .announceCard {
    flex: 0 0 100%;
  }
}
